import React from "react"; 
import { formatDate , formatTime } from "../utils/utils";
const styles = require("../styles/appDashboard.module.css");
import { navigate } from "gatsby";

const AppList = ({data , team, role}) => {
    return(
        <div className = {styles.cls_AppListWrapper}>
            <div className = {styles.cls_AppListHeadCont}>
                <div className = {styles.cls_AppListTitle}>Apps</div>
                <div className = {styles.cls_AppListCreateBtn} onClick = {() => navigate(`/user/apps/createApp?team=${team}`)}>Create An App</div>
            </div>
            <div className = {styles.cls_AppListBody}>
                <div className = {styles.cls_AppListCont}>
                    {
                        data.map((item,idx) => {
                            return (
                                <div className = {styles.cls_AppListItem} key = {idx} onClick = {() => navigate(`/user/apps/appDetail?app=${item.app_id}`)}>
                                    <div className = {styles.cls_AppList}>
                                        <div className = {styles.cls_AppNameWrap}>
                                            <img
                                            src={"../../../connection-white.png"}
                                            alt="SMITCH logo" height="40" width="40" />
                                            <div className = {styles.cls_AppName}>{item.name}</div>
                                        </div>
                                        <div className = {styles.cls_AppUpdatedDetails}>
                                            <div className = {styles.cls_AppUpdatedTitle}> Last Updated </div>
                                            <div className = {styles.cls_AppUpdatedTime}> {formatDate(item.updated_at)} </div>
                                            <div className = {styles.cls_AppUpdatedTime}> {formatTime(item.updated_at)} </div>
                                        </div>
                                    </div>
                                    <div className = {styles.cls_AppStatus}> 
                                        {
                                            item.state === "dev" || item.state === "test" ?
                                                <div className = {styles.cls_inDevBtn}> {item.state} </div>
                                            : 
                                                <div className = {styles.cls_LiveBtn}> {item.state} </div>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default AppList;