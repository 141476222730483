import { makeStyles, Tab, Tabs, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Box, FormControl, InputLabel, Select, MenuItem, FormHelperText, Button } from "@material-ui/core";
import React, {useState , useEffect, useContext } from "react";
import { API_URL } from "../../constants/constants";
import TabPanel from "../TabPanel";
const styles = require("../../styles/appDashboard.module.css");
import { toast } from "react-toastify";
import {navigate} from "gatsby";
import { Apps, PeopleAlt, Settings } from "@material-ui/icons";
import AppList from "../AppList";
import { requestHandler } from "../../utils/utils";
import Teams from "../Teams";
import AppSettings from "../Settings";
import { Formik } from "formik";
import TeamContext from "../../context/TeamContextProvider";
import DeleteModal from "../DeleteModal";

const useStyles = makeStyles(() => ({
    tab: {
      color: '#FFF',
      '&.Mui-selected': {
        color: '#0053C7'
      }
    },
}));

const AppDashboard = () => {
    const classes = useStyles();
    const tabClasses = {root: classes.tab};
    const [ listData , setData ] = React.useState([]);
    const queryParams = new URLSearchParams(window.location.search);
    const tab = queryParams.get("tab") ? queryParams.get("tab") : "0";
    const [ tabValue, setTabValue ] = React.useState(parseInt(tab));
    const [ teamData , setTeamData ] = React.useState();
    const [ inviteDevPopup , showInviteDevPopup ] = React.useState(false);
    const { setUserData , userData} = useContext(TeamContext);
    const [ teamID, setTeamID] = useState("");
    const [ teamName , setTeamName ] = useState("");
    const [ role , setRole ] = useState("");
    const [ deleteConfirm , showDeleteConfirm ] = useState({
        label: "",
        open : false , 
        title: "", 
        key: ""
    });

    useEffect(() => {
        if(userData != null && userData != "") {
            const temp = JSON.parse(userData);
            setTeamID(temp.team_id);
            setTeamName(temp.teamName);
        }
    }, [])

    useEffect(() => {
        if(userData != null && userData != "") {
            const temp = JSON.parse(userData);
            setTeamID(temp.team_id);
            setTeamName(temp.teamName);
        }
    }, [userData])

    useEffect(()=> {
        if(teamID != "") {
            // callAppsAPI();
            callTeamsAPI();
        }
    },[teamID])

    useEffect(() => {
        setTabValue(parseInt(tab))
    }, [tab])

    const handleClose = () => {
        showInviteDevPopup(false)
    };

    const validate = ( values : any) => {
        let errors = {};
        if (values.email === "") {
            Object.assign(errors, {["email"]: "Email cannot be empty"});
        }
        else {
            let regex = /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/;
            if(!regex.test(values.email))
                Object.assign(errors, {["email"]: "Please enter a valid email"});
        }
        if (values.role === "") {
            Object.assign(errors, {["role"]: "Role cannot be empty"});
        }
        return errors;
    };

    const callTeamsAPI = async () => {
        const headers = { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")};
        const result = await requestHandler(API_URL + `team/${teamID}` , null , headers, "GET")
        if(result.status === "success") 
        {
            setTeamData(result.data.developers);
            setData(result.data.apps);
            let userEmail = JSON.parse(userData).email
            let currUser = result.data.developers.find((e) => e.email === userEmail)
            setRole(currUser.role);
        }
        if(result.status === "failed") 
        {
            toast.error(result.errorMessage); 
        }
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }
    }

    const updateAppName = async (app_name) => {
        const data =  {
            "name": app_name,
            "description": app_name
        }
        const headers = { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")};
        const result = await requestHandler(API_URL + `team/${teamID}` , data, headers, "PUT")
        if(result.status === "success") 
        {
            const payload = app_name;
            let temp = JSON.parse(userData);
            temp.teamName = app_name; 
            setUserData( JSON.stringify(temp));
            toast.success(result.message);
        }
        if(result.status === "failed") 
        {
            toast.error(result.errorMessage); 
        }
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }
    }

    const callDeleteDev = async () => {
        const headers = { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")};
        const result = await requestHandler(API_URL + `team/${teamID}/developer?developer_id=${deleteConfirm.key}` , null, headers, "DELETE");
        closeDeleteConfirm();
        if(result.status === "success") 
        {
            toast.success(result.message); 
            callTeamsAPI();
        }
        if(result.status === "failed") 
        {
            toast.error(result.errorMessage); 
        }
        if(result === "login") {
            toast.error("Not authenticated. Please login to continue"); 
            navigate("/login");
        }   
        
    }

    const deleteDevConfirm = (developer_id) => {
        showDeleteConfirm({
            label: "dev",
            title: "Remove access for developer from Team", 
            key : developer_id, 
            open: true
        });
    }

    const callInviteDev = async (values) => {
        try {
            const data = {
                "team_id": teamID,
                "email": values.email,
                "role": values.role
            }
            const result:any = await requestHandler(API_URL + "team/invite-developer" , data , { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")}, "POST")
            if(result.status === "success") 
            {
                toast.success(result.message); 
                handleClose();
            }
            if(result.status === "failed") 
            {
                toast.error(result.errorMessage); 
            }      
        }
        catch (error) {
            // Sentry.captureException(error);
            toast.error(error.errorMessage); 
            console.log("error", error.errorMessage);
        }
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const closeDeleteConfirm = () => {
        showDeleteConfirm({
            label: "",
            open : false , 
            title: "", 
            key: ""
        })
    }

    return (
        <div className = {styles.cls_appDashWrapper}>
        {
            listData.length === 0 && 
            <div className = {styles.cls_appDashEmptyWrapper}>
                <div className = {styles.cls_appDashEmptyHeader}>
                    <img
                        src={"../../../shuttle_white.png"}
                        alt="SMITCH logo" height="116" width="116" />
                    <div className = {styles.cls_appDashEmptyTitle}>
                        Create your first app
                    </div>
                </div>
                <div className = {styles.cls_appDashEmptyBody}>
                    <div className = {styles.cls_appEmptyDesc}>
                        Here in Smitch developer workspace, you will be able to create and manage your apps you made to connect with the Smitch ecosystem. Start by creating your first app today.
                    </div>
                    <div className = {styles.cls_appCreateAppBtn} onClick = {() => navigate(`/user/apps/createApp?team=${teamID}`)}>Create An App</div>
                </div>
            </div>
        }
        {
            listData.length > 0 && 
            <div className = {styles.cls_appListWrapper}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="Vertical tabs"
                    className={styles.cls_appDashVerticalTabs}
                >
                    <Tab classes={tabClasses}  icon={<Apps />} id="vertical-tab-0" className = {styles.cls_appDashTabs}/>
                    <Tab classes={tabClasses}  icon={<PeopleAlt />} id="vertical-tab-1"  className = {styles.cls_appDashTabs}/>
                    <Tab classes={tabClasses}  icon={<Settings />} id="vertical-tab-2"  className = {styles.cls_appDashTabs}/>
                </Tabs>
                <TabPanel value={tabValue} index={0} className = {styles.cls_appDahTabPanel}>
                    <AppList data = {listData} team = {teamID} role = {role} />
                </TabPanel>
                <TabPanel value={tabValue} index={1} className = {styles.cls_appDahTabPanel}>
                    <Teams data = {teamData} role = {role} handleAddDev = {() => showInviteDevPopup(true)} handleDelDev = {deleteDevConfirm}/>
                </TabPanel>
                <TabPanel value={tabValue} index={2} className = {styles.cls_appDahTabPanel}>
                    <AppSettings appName = {teamName} appID = {teamID} handleSubmit = {updateAppName}/>
                </TabPanel>
            </div>
        }
        {
            <Dialog open={inviteDevPopup} onClose={handleClose} aria-labelledby="form-dialog-title" className = "cls_apikeyPopup" fullWidth>
                <DialogTitle id="form-dialog-title" className = {styles.cls_overviewTitle}>Invite a new developer to the team</DialogTitle>
                    <DialogContent>
                        <DialogContentText className = {styles.apiKeyText}>
                            Enter the name and role of the dev
                        </DialogContentText>
                        <Formik
                            initialValues={{
                                "email": "", 
                                "role": ""
                            }}
                            validate={(initialValues) => validate(initialValues)} 
                            onSubmit={async (values: any) => {
                                callInviteDev(values);
                            }}>

                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched, 
                                values,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box>
                                        <TextField
                                            error={Boolean(touched.email && errors.email)}
                                            fullWidth
                                            helperText={touched.email && errors.email}
                                            label="Email"
                                            color="secondary"
                                            margin="normal"
                                            name="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="email"
                                            value={values.email}
                                            variant="standard"
                                        />
                                        <FormControl 
                                            variant="standard" 
                                            fullWidth 
                                            color="secondary"
                                            className = {styles.cls_IndustrySelect}
                                            >
                                            <InputLabel id="Role" error={Boolean(touched.role && errors.role)}>Role *</InputLabel>
                                            <Select
                                            labelId="Role"
                                            id="Role-select"
                                            value={values.role}
                                            onBlur={handleBlur}
                                            color="secondary"
                                            error={Boolean(touched.role && errors.role)}
                                            onChange={(opt, e) => {
                                                handleChange("role")(opt.target.value);
                                            }}>
                                                <MenuItem value="developer">Developer</MenuItem>
                                                <MenuItem value="admin">Admin</MenuItem>
                                            </Select>
                                            { 
                                                touched.role && errors.role && 
                                                <FormHelperText style = {{color: "#f44336"}}>Role cannot be empty</FormHelperText> 
                                            }
                                        </FormControl>
                                    </Box>
                                    <Box my={3} style = {{textAlign : "center"}}>
                                    <DialogActions className = {styles.cls_apiBtnWrapper}>
                                        <div className = {styles.cls_appCancelBtn} onClick={handleClose} color="primary">
                                            Cancel
                                        </div>
                                        <Button
                                            className = {styles.cls_appCreateBtn}
                                            color="primary"
                                            disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                        >
                                            Create
                                        </Button>
                                    </DialogActions>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </DialogContent>
                    
            </Dialog>
        }
        {
            deleteConfirm.label === "dev" && 
            <DeleteModal title = {deleteConfirm.title}  open = {deleteConfirm.open} onDelete = {callDeleteDev}   handleClose = {() => closeDeleteConfirm()} />
        }
        </div>
    )
}

export default AppDashboard;