import { FormHelperText, InputBase } from "@material-ui/core";
import React, { useContext, useState } from "react"; 
import { API_URL } from "../../constants/constants";
import { requestHandler } from "../../utils/utils";
const styles = require("../../styles/appDashboard.module.css");
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { navigate } from "gatsby";

const createApp = () => {
    const [ appName , setAppName ] = useState("");
    const [ error , setError ] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const team_id = queryParams.get("team") ? queryParams.get("team") : "" ; 

    const handleChange = (event) => {
        setAppName(event.target.value);
    }

    const createAnApp = async () => {
        if(appName === "") {
            setError(true);
            return;
        }
        else {
            try {
                const data = {
                    "team_id": team_id, 
                    "name": appName, 
                    "description": appName
                }
                const result:any = await requestHandler(API_URL + "app/" , data, { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")}, "POST")
                if(result.status === "success") 
                {
                    toast.success(result.message); 
                    navigate(`/user/apps?team=${team_id}`)
                }
                if(result.status === "failed") 
                {
                }      
            }
            catch (error) {
                // Sentry.captureException(error);
                toast.error(error.errorMessage); 
                console.log("error", error.errorMessage);
            }
        }
    }

    return (
        <>
            <div className = {styles.cls_createAppDashboard}>
                <div className = {styles.cls_createAppHead}>
                    <div className = {styles.cls_createAppTitle}>Create a new app</div>
                </div>
                <div className = {styles.cls_createAppBody}>
                    <div className = {styles.cls_createAppSubTitle}>App Name</div>
                    <div className = {styles.cls_createAppSubBody}>
                        <div className = {styles.cls_createAppInpWrap}>
                            <InputBase
                                value = {appName}
                                className={styles.cls_createAppInp}
                                onChange = {(e) => handleChange(e)}
                                placeholder=""
                                inputProps={{ 'aria-label': 'App Name' }}
                            />
                            
                        </div>
                        <div className = {styles.cls_subsBtn} onClick = {() => createAnApp()}>
                            Create App
                        </div>
                    </div>
                    { 
                        error && 
                        <FormHelperText className = {styles.cls_errorText}>Please enter App name</FormHelperText> 
                    }
                </div>
            </div>
        </>
    )
}

export default createApp;