import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@material-ui/core";
import React, { useState } from "react";
const styles = require("../styles/appDetail.module.css");

const Overview = ({data, handleApiKeyBtnClick , handleRevokeAccess, handleAppDelete , handleUpdateApp}) => {
    const [ editApp , showEditPopup ] = useState(false);
    const [ appName , changeAppName ] = useState("");
    const [ appDesc, changeAppDesc ] = useState("");
    const [ appNameErr, setAppNameErr ] = useState("");
    const [ appDescErr, setAppDescErr ] = useState("");

    const handlePopupClose = () => {
        showEditPopup(false);
        changeAppName("");
        changeAppDesc("");
        setAppNameErr("");
        setAppDescErr("");
    }

    const handleSave = () => {
        if(appName === "" || appDesc === "") {
            if(appName === "")
                setAppNameErr("App Name cannot be empty");
            if(appDesc === "")
                setAppDescErr("App Description cannot be empty");
        }
        else {
            handleUpdateApp(appName, appDesc , data.app_id);
            handlePopupClose();
        }
    }

    return (
        <>
        <div className = {styles.cls_overViewWrapper}>
            <div className = {styles.cls_overViewPage}>
                <div className = {styles.cls_overviewPart1}>
                    <div className = {styles.cls_overviewTitleWrap}>
                        <div className = {styles.cls_overviewTitle}> App ID: <span className = {styles.cls_overviewTitleSpan}>{data.app_id}</span></div>
                        <div className = {styles.cls_overviewAppStatusWrap}>
                            {
                                data.state === "dev" || data.state === "test" ? 
                                <div className = {styles.cls_overViewAppDev}>{data.state}</div>
                                : 
                                <div className = {styles.cls_overviewAppLive}>{data.state}</div>
                            }
                        </div>
                    </div>
                    <div className = {styles.cls_overviewDescWrap}>
                        <div className = {styles.cls_overviewDesc}>Connect to MySmitch Platform API </div>
                        <div className = {styles.cls_overviewAppEditBtn}>
                            <div className = {styles.cls_overviewEdit} onClick = {() => showEditPopup(true)}>Edit</div>
                        </div>
                    </div>
                </div>
                <div className = {styles.cls_overviewPart1}>
                    <div className = {styles.cls_overViewAPIKeyHead}>
                        <div className = {styles.cls_overviewTitle}>API Keys</div>
                        <div className = {styles.cls_overviewBtnWrap}>
                            <div className = {styles.cls_overviewAddAPIBtn} onClick = {() => handleApiKeyBtnClick()}>
                                Add API Key
                            </div>
                        </div>
                    </div>
                    {
                        data.app_api_keys && data.app_api_keys.length == 0 &&
                        <div className = {styles.noApiKeysText}> No API Keys. Add one </div>
                    }
                    {
                        data.app_api_keys && data.app_api_keys.length > 0 &&
                        <TableContainer component={Paper} className = {styles.cls_apiTableCont}>
                            <div className={styles.cls_apiTableWrapper}>
                                <Table  aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="left">Key Prefix</TableCell>
                                    <TableCell align="left">Scope</TableCell>
                                    <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.app_api_keys.map((row) => (
                                    <TableRow key={row.api_key_id}>
                                        <TableCell component="th" scope="row">
                                        {row.name}
                                        </TableCell>
                                        <TableCell align="left">{row.api_key_id}</TableCell>
                                        <TableCell align="left">All</TableCell>
                                        <TableCell align="right"><div className = {styles.revokeAccessBtn} onClick = {() => handleRevokeAccess(row.api_key_id)}>Revoke Access</div></TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                                </Table>
                            </div>
                        </TableContainer>
                    }
                </div>
                <div className = {styles.cls_overviewPart1}>
                    <div className = {styles.cls_overviewTitle}>Delete this App</div>
                    <div className = {styles.cls_overviewDeleteApp}>
                        <div className = {styles.cls_overViewWarningText}>If you delete this app, you’ll not be able to recover it</div>
                        <div className = {styles.cls_overviewBtnWrap}>
                            <div className = {styles.cls_overViewDeleteBtn} onClick = {() => handleAppDelete()}>
                                Delete App
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        {
            <Dialog open={editApp} onClose={handlePopupClose} aria-labelledby="form-dialog-title" className = "cls_editAppPopup" fullWidth>
                <DialogTitle id="form-dialog-title" className = {styles.cls_overviewTitle}>Edit App Details</DialogTitle>
                    <DialogContent>
                        <DialogContentText className = {styles.apiKeyText}>
                            Enter the details of the App
                        </DialogContentText>
                        <TextField  
                            value = {appName}
                            onChange = {(event) => changeAppName(event.target.value)}
                            autoFocus
                            color="secondary"
                            id="name"
                            className = {styles.cls_appDetinputBox}
                            label="name"
                            type="text"
                            fullWidth
                        />
                        {
                            appNameErr !== "" && 
                            <DialogContentText className = {styles.apiKeyTextError}>
                                {appNameErr}
                            </DialogContentText>
                        }
                        <TextField  
                            value = {appDesc}
                            onChange = {(event) => changeAppDesc(event.target.value)}
                            autoFocus
                            color="secondary"
                            id="description"
                            className = {styles.cls_appDetinputBox}
                            label="description"
                            type="text"
                            fullWidth
                        />
                        {
                            appDescErr !== "" && 
                            <DialogContentText className = {styles.apiKeyTextError}>
                                {appDescErr}
                            </DialogContentText>
                        }
                    </DialogContent>
                    <DialogActions className = {styles.cls_apiBtnWrapper}>
                        <div className = {styles.cls_appCancelBtn} onClick={handlePopupClose} color="primary">
                            Cancel
                        </div>
                        <div className = {styles.cls_appCreateBtn} onClick={() => handleSave()}color="primary">
                            Save
                        </div>
                    </DialogActions>
            </Dialog>
        }
        </>
    )
}

export default Overview;

